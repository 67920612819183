import React from 'react'
import TokenomicsSection from '../../Components/TokenomicsSection'
import StatsSections from '../../Components/StatsSections'

const Tokenomics = () => {
  return (
    <div className='pt-12'>
        <TokenomicsSection/>
        <StatsSections/>
    </div>
  )
}

export default Tokenomics