import React from 'react'
import MissionHero from './MissionHero'
import AboutMission from './AboutMission'

const Mission = () => {
  return (
    <div>
        <MissionHero/>
        <AboutMission/>
    </div>
  )
}

export default Mission