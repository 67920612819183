import React from 'react'
import HeroSection from './HeroSection'

const About = () => {
  return (
    <div>
        <HeroSection/>
    </div>
  )
}

export default About